const footerMenuList = [
  {
    id: 1,
    title: "Marketplace",
    diffClass: "md:col-start-7",
    list: [
      {
        id: 1,
        href: "#",
        text: "All NFTs",
      },
      {
        id: 2,
        href: "#",
        text: "art",
      },
      {
        id: 3,
        href: "#",
        text: "music",
      },
      {
        id: 4,
        href: "#",
        text: "domain names",
      },
      {
        id: 5,
        href: "#",
        text: "collections",
      },
      {
        id: 6,
        href: "#",
        text: "virtual world",
      },
    ],
  },
  {
    id: 2,
    title: "Company",
    diffClass: "",
    list: [
      {
        id: 1,
        href: "#",
        text: "Explore",
      },
      {
        id: 2,
        href: "#",
        text: "About",
      },
      {
        id: 3,
        href: "#",
        text: "Contact Us",
      },
      {
        id: 4,
        href: "#",
        text: "Our Blog",
      },
      {
        id: 5,
        href: "#",
        text: "FAQ",
      },
    ],
  },
  {
    id: 3,
    title: "My Account",
    diffClass: "",
    list: [
      {
        id: 1,
        href: "#",
        text: "Authors",
      },
      {
        id: 2,
        href: "#",
        text: "Collection",
      },
      {
        id: 3,
        href: "#",
        text: "Author Profile",
      },
      {
        id: 4,
        href: "#",
        text: "Create Item",
      },
    ],
  },
];

const socialIcons = [
  // {
  //   id: 1,
  //   href: "https://www.facebook.com",
  //   text: "facebook",
  // },
  {
    id: 2,
    href: "https://x.com/SolLottoTeam",
    text: "twitter",
  },
  {
    id: 3,
    href: "https://discord.com/channels/1251205632929955851/1251618884314992702",
    text: "discord",
  },
  {
    id: 4,
    href: " https://www.instagram.com/sollottoteam/",
    text: "instagram",
  },
  {
    id: 5,
    href: "/#",
    text: "tiktok",
  },
];

export { footerMenuList, socialIcons };
